export function useTrading() {
  function toPriceChangeClass(value) {
    if (value > 0) {
      return 'text-green';
    } else if (value < 0) {
      return 'text-red';
    } else {
      return 'text-white';
    }
  }

  return {
    toPriceChangeClass,
  };
}
